import { createGlobalStyle } from 'styled-components';

import ResetCss from './reset-css';

const GlobalStyle = createGlobalStyle`
  ${ResetCss}
  html.ui-html-no-scroll{ 
  overflow: hidden;
  }
  body {
    margin: 0;
    font-family: var(--ui-font-family-body);
    font-weight: var(--ui-font-weight-medium);
    font-size: var(--ui-font-body-size-md);
    line-height: var(--ui-font-body-line-height-md);
    color: var(--ui-colors-grey-dark);

    ::selection {
      color: var(--ui-colors-white);
      background-color: var(--ui-colors-primary-default);
    }

    h1,
    h2,
    h3,
    h4 {
      font-family: var(--ui-font-family-heading);
    }
    
    sup {
      vertical-align: super;
      font-size: smaller;
      align-self: end;
      a{
        color:${(props) => props.theme.colors.primary.default};
        margin-left: 4px;
        cursor: pointer;
        text-decoration:none !important;
      }
    }
    sub {
      vertical-align: sub;
      font-size: smaller;
      align-self:start;
    }
  }

  .markdown-space-preserve {
    white-space: pre-wrap;
  }

  // padding needed to add space between the bar and the hero  in portuguese LPs
  .hero-media div:nth-of-type(1) > div {
    @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
      padding-top: 32px !important;
    }
  }

  html {
    box-sizing: border-box;
    overflow-x: hidden;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  
  
  /* Enzuzo Cookie Banner */
  
  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    #ez-cookie-notification{
      bottom: 85px !important;
    }
  }

.enzuzo-cookiebanner-container,
.notification-main,
.notification-bottom-right,
.bottom-right-medium,
.bottom-right-rounded,
.has-rounded,
.enzuzo-animate,
.enzuzo-none,
.enzuzo-shadow {
  opacity: 0 !important;
  pointer-events: none;

  &.show {
    opacity: 1 !important;
    pointer-events: auto;
  }
}
  .ez-consent {
    --ez-preferences-switch-on: var(--ui-colors-primary-default) !important;

    .notification-main.enzuzo-shadow {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2) !important;
    }

    .notification-button,
    button.enzuzo-modal-button {
      border-radius: 100px !important;
      border-width: 1px !important;
      font-family: var(--ui-font-family-heading) !important;
      font-size: var(--ui-font-body-size-sm) !important;
      line-height: 1.25rem;
      padding: 10px var(--ui-spacings-md) !important;

      &:hover {
        background-color: var(--ui-colors-grey-default) !important;
        color: var(--ui-colors-white) !important;
        border-color: var(--ui-colors-grey-default) !important;
      }
    }

    .notification-main .notification-buttons {
      flex-direction: column-reverse;
      padding-top: var(--ui-spacings-sm);
    }

    .cookieButtonWrap {
      flex-direction: row !important;
    }

    #notificationManagerLink {
      margin: 0 !important;
      white-space: nowrap;
    }

    .enzuzo-poweredby-wrap {
      display: none !important;
    }
  }

  /* Enzuzo Cookie Banner */
.ez-consent {
  --ez-banner-link-color: var(--ui-colors-grey-dark) !important;
  --ez-preferences-switch-on:var(--ui-colors-primary-default) !important;
  --ez-banner-decline-background-color: var(--ui-colors-white) !important;
  --ez-banner-decline-background-color-hover: var(--ui-colors-grey-default) !important;
  --ez-banner-decline-text-color: var(--ui-colors-grey-dark) !important;
  --ez-banner-decline-border-color: var(--ui-colors-grey-dark) !important;
  --ez-banner-allow-all-background-color-hover: var(--ui-colors-grey-default) !important;
  --ez-preferences-allow-selected-background-color-hover: var(--ui-colors-grey-default) !important;

  .notification-main {
    border-radius: 4px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2) !important;
    flex-direction: column !important;
    max-width: 380px !important;
    padding: 32px !important;
    left: auto !important;
    right: 20px !important;
    bottom: 20px !important;
    transform: none !important;
    width: calc(100% - 64px) !important;

    .notification-text-medium {
      font-size: var(--ui-font-body-size-sm)!important;
    }

    .notification-desc {
      p:last-of-type {
        display: inline !important;
      }

      #notificationPolicyLink {
        display: inline !important;
      }
    }

    .notification-buttons {
      flex-direction: column-reverse;
      padding-top: var(--ui-spacings-sm);
    }

    .closeTrigger {
      padding-right: 0 !important;
    }
  }

  .notification-button,
  button.enzuzo-modal-button,
  button.button-default,
  button.button-rounded,
  .notification-main button.button-default,
  .notification-main button.button-rounded {
    border-radius: 100px !important;
    border-width: 1px !important;
    font-family: var(--ui-font-family-heading) !important;
    font-size: var(--ui-font-body-size-sm) !important;
    line-height: 1.25rem;
    padding: 10px  var(--ui-spacings-md) !important;
    transition: all 80ms ease-in-out !important;

    &:hover {
      color:  var(--ui-colors-white) !important;
      border-color: var(--ez-banner-allow-all-background-color-hover) !important;
    }
  }

  .cookieButtonWrap {
    flex-direction: row !important;
  }

  #notificationManagerLink {
    margin: 0 !important;
    white-space: nowrap;
  }

  .enzuzo-close-banner-button,
  .enzuzo-poweredby-wrap {
    display: none !important;
  }
  
  .enzuzo-modal-button-close {
    display: flex !important;
    align-items: center !important;
  }
}

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    &[aria-labelledby="insurance-details-form-insurance"] {
      max-height: 144px;
    }
  }

`;

export default GlobalStyle;
